import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectLookups (Vue, idsParametros, esTecnico, esComercial, esVigilante) {
    let apiCalls = []
    const apiFilterTactuacionTsistema = new APIFilter()
    apiFilterTactuacionTsistema.addGT('estado', 0)
    apiFilterTactuacionTsistema.addGT('tactuacion_estado', 0)
    apiFilterTactuacionTsistema.addGT('tsistema_estado', 0)
    apiCalls.push({ name: 'selectTactuacionTsistema', method: 'tactuacionTsistema.select', params: { filter: apiFilterTactuacionTsistema } })
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    apiCalls.push({ name: 'selectPlantillaArticulo', method: 'plantillaArticulo.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectAgenteComercial', method: 'agenteComercial.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectCanalComunicacion', method: 'canalComunicacion.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectCanalConocimiento', method: 'canalConocimiento.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTipoRiesgo', method: 'tipoRiesgo.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectGradoSeguridad', method: 'gradoSeguridad.select', params: { filter: apiFilterEstadoActivo } })
    const apiFilterPago = new APIFilter()
    apiFilterPago.addGT('estado', 0)
    apiFilterPago.addExact('cliente', 1)
    apiCalls.push({ name: 'selectFormaPago', method: 'formaPago.select', params: { filter: apiFilterPago } })
    apiCalls.push({ name: 'selectCondicionPago', method: 'condicionPago.select', params: { filter: apiFilterPago } })
    const apiFilterParametros = new APIFilter()
    apiFilterParametros.addIn('idparametro', idsParametros)
    apiCalls.push({ name: 'selectParametro', method: 'parametro.select', params: { filter: apiFilterParametros } })
    const apiFilterFicheros = new APIFilter()
    apiFilterFicheros.addGT('estado', 0)
    const apiFilterVisualizacion = new APIFilter()
    apiFilterVisualizacion.setOperator(API_FILTER_OPERATORS.OR)
    if (esTecnico) {
      apiFilterVisualizacion.addExact('visible_movilidad_sat', esTecnico)
    }
    if (esComercial) {
      apiFilterVisualizacion.addExact('visible_movilidad_vigilancia', esComercial)
    }
    if (esVigilante) {
      apiFilterVisualizacion.addExact('visible_movilidad_sat', esVigilante)
    }
    apiFilterFicheros.addNestedFilter(apiFilterVisualizacion)
    apiCalls.push({ name: 'selectTfichero', method: 'tfichero.select', params: { filter: apiFilterFicheros } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectLookupsCliente (Vue, idcliente) {
    let apiCalls = []
    const apiFilterCliente = new APIFilter()
    apiFilterCliente.addGT('estado', 0)
    apiFilterCliente.addExact('idcliente', idcliente)
    apiCalls.push({ name: 'selectSistema', method: 'sistema.select', params: { filter: apiFilterCliente } })
    apiCalls.push({ name: 'selectDireccion', method: 'clienteDireccion.select', params: { filter: apiFilterCliente } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectLookupsClientePotencial (Vue, idclientePotencial) {
    let apiCalls = []
    const apiFilterClientePotencial = new APIFilter()
    apiFilterClientePotencial.addGT('estado', 0)
    apiFilterClientePotencial.addExact('idcliente_potencial', idclientePotencial)
    apiCalls.push({ name: 'selectDireccion', method: 'clienteDireccion.select', params: { filter: apiFilterClientePotencial } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectLookupsDatosPresupuesto (Vue, idmtdato) {
    let apiCalls = []
    const apiFilterMtdato = new APIFilter()
    apiFilterMtdato.addGT('estado', 0)
    apiFilterMtdato.addExact('idmtdato', idmtdato)
    apiCalls.push({ name: 'selectMtdato', method: 'mtdato.select', params: { filter: apiFilterMtdato } })
    apiCalls.push({ name: 'selectTdato', method: 'tdato.select', params: { filter: apiFilterMtdato } })
    apiCalls.push({ name: 'selectTgrupoTdato', method: 'tgrupoTdato.select', params: { filter: apiFilterMtdato } })
    apiCalls.push({ name: 'selectTdatoValor', method: 'tdatoValor.select', params: { filter: apiFilterMtdato } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectClienteSearch (Vue, search) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('estado', 1)
    apiFilter.addNestedFilter(Vue.$online.cliente.searchFilter(search))
    const resp = await Vue.$api.call(
      'cliente.select',
      {
        filter: apiFilter,
        page: 0,
        page_size: Vue.$api.ITEMS_PER_PAGE_AUTOCOMPLETE,
      },
      {
        disableUI: false,
      },
    )
    return resp.data.result.dataset
  },
  async selectClientePotencialSearch (Vue, search) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('estado', 1)
    apiFilter.addNestedFilter(Vue.$online.clientePotencial.searchFilter(search))
    const resp = await Vue.$api.call(
      'clientePotencial.select',
      {
        filter: apiFilter,
        page: 0,
        page_size: Vue.$api.ITEMS_PER_PAGE_AUTOCOMPLETE,
      },
      {
        disableUI: false,
      },
    )
    return resp.data.result.dataset
  },
  async selectCliente (Vue, idcliente) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idcliente', idcliente)
    const resp = await Vue.$api.call('cliente.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async selectClientePotencial (Vue, idclientePotencial) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idcliente_potencial', idclientePotencial)
    const resp = await Vue.$api.call('clientePotencial.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async selectClienteDireccion (Vue, idclienteDireccion) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('idcliente_direccion', idclienteDireccion)
    const resp = await Vue.$api.call('clienteDireccion.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
}
